<template>
  <app-side-menu>
    <aside class="menu"
      slot="side-menu">
      <ul v-for="companySetting in companySettings"
        :key="companySetting.to.name"
        class="menu-list">
        <li>
          <router-link :to="companySetting.to"
            active-class="is-active"
            exact>
            <span>
              {{ companySetting.label }}
            </span>
          </router-link>
        </li>
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import CompanySettingRoutes from './route-types'

export default {
  name: 'CompanySettingSideMenu',
  components: {
    AppSideMenu
  },
  mixins: [],
  props: {},
  computed: {
    companySettings() {
      return [
        {to: {name: CompanySettingRoutes.CompanyDetailSetting.name}, label: 'Details'},
        {to: {name: CompanySettingRoutes.CompanyLicenseSetting.name}, label: 'License'},
        {to: {name: CompanySettingRoutes.CompanyModulesSetting.name}, label: 'Modules'},
        {to: {name: CompanySettingRoutes.CompanyExternalSetting.name}, label: 'Integration'},
        {to: {name: CompanySettingRoutes.ItemCategorySetting.name}, label: 'Item Category'},
        {to: {name: CompanySettingRoutes.CompanyInternalSetting.name}, label: 'Preferences'},
        {to: {name: CompanySettingRoutes.CompanyEmailSetting.name}, label: 'Two-Way Emails'},
        {to: {name: CompanySettingRoutes.CompanyMonthlySetting.name}, label: 'Shop Targets'}
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    next()
  },
  beforeRouteLeave(to, from, next) {
    next()
  }
}
</script>

<style lang="scss" scoped>
.menu {
  min-height: calc(100vh - 6.5rem - 2rem - 2rem); // 6.5rem=toolbars, 2rem=child tile padding, 2rem=parent tile padding
}
</style>
